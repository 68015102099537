import React, {useEffect} from "react";
import HelmetIndex from "../components/helmetIndex";
import { navigate } from "gatsby";


export default ({ location }) => {
  useEffect(() => {

    navigate('/landingpageb');
    
    return () => {
     
      
    };
  }, []);


  


  return (

    <div>
      <HelmetIndex />
    </div>
  );
};
